/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import SocialMedia from '../socialmedia/SocialMedia';

const Footer = () => (
  <Box as="footer" bg="primary" color="background" p={4}>
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span sx={{ mb: 3 }}>
        ©
        {' '}
        {new Date().getFullYear()}
        {' '}
        Revolight AB located in Stockholm
        {' '}
      </span>
      <SocialMedia position="center" fill="background" />
    </div>
  </Box>
);

export default Footer;
